import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FrontHeader from "../components/front-header";
import About from "../components/about";
import TechnologyStack from "../components/technology-stack";
import TimelineJourney from "../components/timelinev3";
import Resume from "../components/resume";
import { disableBodyScroll } from "body-scroll-lock";

const IndexPage = () => {
  const [layoutDimensions, setLayoutDimensions] = React.useState({
    frontHeader: 0,
    about: 0,
    technology: 0,
  });

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    disableBodyScroll(document.body);
  }, []);

  React.useEffect(() => {
    setLayoutDimensions({
      ...layoutDimensions,
      frontHeader: document.getElementById("front-header").clientHeight,
      about: document.getElementById("about").clientHeight,
      technology: document.getElementById("technology").clientHeight,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutDimensions.frontHeader,
    layoutDimensions.about,
    layoutDimensions.technology,
  ]);

  return (
    <Layout>
      <SEO
        keywords={[
          `pratik kataria`,
          `portfolio`,
          `react`,
          `gatsby`,
          `developer`,
          `android`,
          `wordpress`,
          `angular`,
          `python`,
          `pratik`,
        ]}
      />
      <FrontHeader />
      <About />
      <TechnologyStack />
      {layoutDimensions.frontHeader > 0 ? (
        <TimelineJourney layoutDimensions={layoutDimensions} />
      ) : null}
      <Resume />
    </Layout>
  );
};

export default IndexPage;
