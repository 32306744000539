import React from "react";
import styled from "styled-components";
import { Section } from "react-scroll-section";
import { TextSwoopAnimated } from "../shared/animation-component";
import TechRevealer from "./tech-revealer";
import { technologies } from "../data/technology-stack";
import { isMobile } from "react-device-detect";
const Slider = React.lazy(() => import("react-slick"));

const RightSubSection = styled.div`
  z-index: 2;
  display: inline-flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.color_theme_primary};
  color: ${(props) => props.theme.color_text_lighter};
  flex: 1 1 50%;
  align-self: stretch;
  text-align: justify;
  padding: ${(props) => props.theme.spacing_l};
  overflow: hidden; /* for animation */
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  flex-direction: ${(props) => props.theme.flex_direction_mobile};
  @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
    flex-direction: ${(props) => props.theme.flex_direction_desktop};
  }
`;

const LeftSubSection = styled.div`
  z-index: 2;
  flex: 1 1 50%;
  position: relative;
  align-self: stretch;
  text-align: justify;
  padding: ${(props) => props.theme.spacing_l};
  background-color: ${(props) => props.theme.color_theme_dark};
  color: ${(props) => props.theme.color_text_lighter};
  font-size: ${(props) => props.theme.fit_text_size_s};
  line-height: ${(props) => props.theme.fit_text_line_height_s};
  @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
    font-size: ${(props) => props.theme.fit_text_size_m};
    line-height: ${(props) => props.theme.fit_text_line_height_m};
  }
  @media only screen and (min-width: ${(props) => props.theme.tablet_bp}) {
    font-size: ${(props) => props.theme.fit_text_size_l};
    line-height: ${(props) => props.theme.fit_text_line_height_l};
  }
  @media only screen and (min-width: ${(props) =>
      props.theme.mobile_bp}) and (max-width: 1168px) {
    &:after {
      content: "";
      width: calc(95% - 18px);
      height: 4px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: ${(props) => props.theme.color_theme_primary};
    }
  }
`;

const Frame = styled.div`
  align-items: center;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100px;
  margin: auto;
  margin-top: -48px;
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  swipeToSlide: true,
  centerPadding: "20px",
};

const TechnologyStack = () => {
  return (
    <Section id="technology">
      <Frame>
        <Container>
          <LeftSubSection>
            <TextSwoopAnimated>
              I am a T-shaped developer with keen interest in full stack
              development, problem solving and hybrid mobile development. Over
              the years of my learning and experience, I have developed
              versatile technology stack that can sustain me in most of the
              challenges. However, my expertise lies in Front-End.
            </TextSwoopAnimated>
          </LeftSubSection>
          <RightSubSection>
            {isMobile ? (
              <SliderContainer>
                <React.Suspense fallback={<p>Loading...</p>}>
                  <Slider {...settings}>
                    {technologies.map((value, index) => {
                      return (
                        <TechRevealer
                          key={value.id}
                          name={value.name}
                          svg={value?.svg}
                        />
                      );
                    })}
                  </Slider>
                </React.Suspense>
              </SliderContainer>
            ) : (
              technologies.map((value, index) => {
                return (
                  <TextSwoopAnimated key={value.id}>
                    <TechRevealer name={value.name} svg={value?.svg} />
                  </TextSwoopAnimated>
                );
              })
            )}
          </RightSubSection>
        </Container>
      </Frame>
    </Section>
  );
};

export default TechnologyStack;
