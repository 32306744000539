import React from "react";
import { useSpring } from "react-spring";
import { getElementVisibility } from "./utils";

const useAnimationAsPerVisibility = (element, isVisible) => {
  const [{ visibility, isRested }, set] = useSpring(() => ({
    visibility: 0,
    isRested: 0,
  }));
  React.useEffect(() => {
    function getVisibilityPercent() {
      const percentObj = getElementVisibility(element);
      set({
        visibility: percentObj.topToBotVisibilityPercent,
        isRested: percentObj.topToBotVisibilityPercent >= 100 ? 1 : 0,
      });
      return percentObj;
    }
    function watchScroll() {
      window.addEventListener("scroll", getVisibilityPercent);
    }
    function endWatch() {
      window.removeEventListener("scroll", getVisibilityPercent);
    }
    if (isVisible) {
      watchScroll();
    } else {
      endWatch();
    }
    return endWatch;
  }, [isVisible, set, element]);
  return { visibility, isRested };
};

export default useAnimationAsPerVisibility;
