import React from "react";
import { Spring, config, animated } from "react-spring/renderprops";
import { animated as a, interpolate } from "react-spring";
import styled, { css } from "styled-components";
import VizSensor from "react-visibility-sensor";
import { scale } from "./utils";
import useAnimationAsPerVisibility from "./animate-with-visibility";

export const TextSwoopAnimated = (props) => {
  const { children, containerStyle = {} } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const divRef = React.useRef();
  const { visibility, isRested } = useAnimationAsPerVisibility(
    divRef.current,
    isVisible
  );
  const interpTransform = interpolate([visibility, isRested], (o, isRested) =>
    isRested === 1
      ? `translate(0px, ${scale(o, 0, 100, 80, 0)}px)`
      : `translate3d(0px, ${scale(o, 0, 100, 80, 0)}px, 0px)`
  );
  const interpTransformChild = interpolate(
    [visibility, isRested],
    (o, isRested) =>
      isRested === 1
        ? `translate(0px, 0px)`
        : `translate3d(0px, 0px, 0px) rotate(${scale(o, 0, 100, 7, 0)}deg)`
  );
  const interpLineHeight = interpolate([visibility], (o) =>
    scale(o, 0, 100, 2.525, 1.8)
  );
  const onChange = React.useCallback(
    (v) => {
      setIsVisible(v);
    },
    [setIsVisible]
  );
  return (
    <a.div
      style={{
        ...containerStyle,
        transform: interpTransform,
      }}
      ref={divRef}
    >
      <VizSensor onChange={onChange} partialVisibility>
        <a.div
          style={{
            transformOrigin: "50% 50%",
            transform: interpTransformChild,
            lineHeight: interpLineHeight,
          }}
        >
          {children}
        </a.div>
      </VizSensor>
    </a.div>
  );
};

export const TextSwoopIn = (props) => {
  const { children } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const from = {
    transform: "matrix3d(1, 0.1, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 120, 0, 1)",
    opacity: 0,
  };
  const to = {
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    opacity: 1,
  };
  return (
    <VizSensor
      onChange={(visibility) => setIsVisible(visibility)}
      active={!isVisible}
      partialVisibility
      offset={{ bottom: 150 }}
    >
      <Spring
        native
        config={config.default}
        from={from}
        to={isVisible ? to : from}
      >
        {(props) => <animated.div style={props}>{children}</animated.div>}
      </Spring>
    </VizSensor>
  );
};

const Overlay = styled(animated.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
`;

const AbsoluteContainer = styled.div`
  position: relative;
  overflow: hidden;
  picture img {
    transition: transform 1s ease ${({ delay }) => `${delay}ms`},
      opacity 500ms ease ${({ delay }) => `${delay}ms`} !important;
  }
  &.scale {
    picture img {
      transform: scale(1);
    }
  }
  &.scaled-up {
    picture img {
      transform: scale(1.5);
    }
  }
`;

export const ImageReveal = (props) => {
  const { children, delay = 0, overlayBgColor = "#fff" } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const [isAnimationEnded, setIsAnimationEnded] = React.useState(false);
  const from = {
    transform: "translateX(0%)",
  };
  const to = {
    transform: "translateX(100%)",
  };
  return (
    <VizSensor
      onChange={(visibility) => setIsVisible(visibility)}
      active={!isVisible}
      partialVisibility
      offset={{ bottom: 150 }}
    >
      <Spring
        native
        config={config.slow}
        to={isVisible ? to : from}
        immediate={!isVisible}
        delay={delay}
        onRest={() => setIsAnimationEnded(true)}
      >
        {(props) => (
          <AbsoluteContainer
            className={isVisible ? "scale" : "scaled-up"}
            delay={delay}
          >
            {!isAnimationEnded && (
              <Overlay style={{ ...props, backgroundColor: overlayBgColor }} />
            )}
            {children}
          </AbsoluteContainer>
        )}
      </Spring>
    </VizSensor>
  );
};
