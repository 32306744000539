import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./timeline.scss";
import { useSpring, animated } from "react-spring";
import { isMobile } from "react-device-detect";
import {
  FaRobot,
  FaYoutube,
  FaAndroid,
  FaWordpress,
  FaDatabase,
  FaTrophy,
  FaGraduationCap,
  FaTumblr,
  FaRegUserCircle,
  FaReact,
} from "react-icons/fa";
import { IoLogoIonic } from "react-icons/io";
import TimelineComponent from "../shared/timeline-component";
import styled, { css } from "styled-components";
import { Section } from "react-scroll-section";
import {
  GlassHeading,
  NeonBlink,
  StrokedButton,
} from "../shared/styled-components";
import reachedElement, { offsetElement } from "../shared/utils";
import "./stars.sass";
import VizSensor from "react-visibility-sensor";
const Slider = React.lazy(() => import("react-slick"));

const Frame = styled.div`
  /* background-color: ${(props) => props.theme.color_theme_dark}; */
  background-image: linear-gradient(
    to bottom,
    #252c36,
    #3d3959,
    #703d6c,
    #a73b65,
    #cf4646
  );
  overflow: hidden;
`;

const HeadingFrame = styled.div`
  height: 280px;
  width: 90%;
  margin: 0 auto;
  max-width: 1170px;
  position: relative;
  display: flex;
  ::before {
    left: 50.2%;
    margin-left: -2px;
    content: "";
    position: absolute;
    height: 280px;
    width: 4px;
    background-color: #cf4646;
    background-color: var(--color-theme-primary);
    @media only screen and (max-width: 1168px) {
      left: 18px;
      margin-left: 0px;
    }
  }
  @media only screen and (max-width: 1168px) {
    width: 95%;
  }
`;

const Heading = styled(GlassHeading)`
  color: #fee;
  animation: ${(props) =>
    props.blink
      ? css`
          ${NeonBlink} 2000ms linear 0.01s 1 reverse
        `
      : "none"};

  text-align: center;
  line-height: 2rem;
  text-shadow: ${(props) => props.theme.text_shadow_neon_glow};
  @media only screen and (max-width: 1168px) {
    margin: auto 0;
  }
`;

const AnimatedBackground = styled(animated.div)`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  will-change: transform;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 9rem;
  border-radius: 5px;
  padding: 10px;
  background-color: ${(props) => props.theme.color_theme_primary};
  z-index: 2;
  justify-content: space-between;
`;

const SliderContainer = styled.div`
  width: 206px;
  height: 42px;
  margin: auto;
`;

const FilterBlock = styled(StrokedButton)`
  &.selected {
    background-color: ${(props) => props.theme.color_theme_dark};
    color: ${(props) => props.theme.color_theme_primary};
    a {
      color: ${(props) => props.theme.color_theme_primary};
    }
  }
`;

const TIMELINE_DATA = [
  {
    dateProp: "May 2008",
    iconProp: <FaYoutube />,
    title: "YouTube Channel",
    subtitle: "Started Early...",
    details: `Shared what I could find over the internet at that time as well as learned through various experiments.`,
    link: "http://www.youtube.com/c/PratikKataria",
    linkText: "Youtube",
  },
  {
    dateProp: "Dec 2013",
    iconProp: <FaTumblr />,
    title: "First Blog",
    subtitle: "Tumblr",
    details: `Started the blog in order to share my thoughts on gaming and anime. Simultaneously I started learning HTML and CSS.`,
    link: "https://pratikkataria.tumblr.com/",
    linkText: "Website",
  },
  {
    dateProp: "Jan 2015",
    iconProp: <FaAndroid />,
    title: "First Android App",
    subtitle: "Formulae Box",
    details: `After a short workshop on android, I started working on an app that helps real students inside and outside of India`,
    link:
      "https://play.google.com/store/apps/details?id=com.tumblr.pratikkataria",
    linkText: "Playstore",
  },
  {
    dateProp: "Sep 2015",
    iconProp: <FaRegUserCircle />,
    title: "First Free Web Hosting",
    subtitle: "Portfolio",
    details: `Advancing my knowledge of HTML, CSS, Web Hosting and little bit of JavaScript.`,
    link: "http://csengineer.byethost32.com/pratikkataria/",
    linkText: "Website",
  },
  {
    dateProp: "Oct 2015",
    iconProp: <FaWordpress />,
    title: "First Website",
    subtitle: "Portfolio",
    details: `Purchased domain, web hosting and worked on wordpress to create my portfolio website`,
    link: "https://wordpress.pratikkataria.com/",
    linkText: "Website",
  },
  {
    dateProp: "Apr 2016",
    iconProp: <FaWordpress />,
    title: "Mini Website Project",
    subtitle: "For fellow students",
    details: `Started out with the intention to help my classmates but ended up brushing my skills of CSS, HTML5 and Wordpress`,
    link: "http://codes.pratikkataria.com/",
    linkText: "CodeForest",
  },
  {
    dateProp: "May 2016",
    iconProp: <FaTrophy />,
    title: "Finalist in MTV Get a Job Season 2 at Flipkart (Top 4)",
    subtitle: "Shortlisted from over 2 lakh candidates",
    details: `Among the 4 finalists for internship at flipkart which was held at Flipkart Headquarters, Bangalore for the position of Mobility Software Engineer`,
    link:
      "https://www.linkedin.com/pulse/mtv-get-job-season-2-flipkart-my-experience-pratik-kataria/",
    linkText: "Article",
  },
  {
    dateProp: "Jul 2016",
    iconProp: <FaWordpress />,
    title: "Website for Conference",
    subtitle: "Created for college IT department",
    details: `ICICC 2017 conference website 'icicc.in', the prototype of which was created on my sub-domain.`,
    link: "http://conference.pratikkataria.com/",
    linkText: "Website",
  },
  {
    dateProp: "Sep 2016",
    iconProp: <FaAndroid />,
    title: "Programming Epitome",
    subtitle: "Android App connected to Wordpress Website as backend",
    details: `Started out creating this app to share the technical knowledge published on my wordpress site directly onto mobile`,
    link:
      "https://play.google.com/store/apps/details?id=com.pratikkataria.codes",
    linkText: "Playstore",
  },
  {
    dateProp: "Oct 2016",
    iconProp: <FaDatabase />,
    title: "MongoDB Backed Web Application",
    subtitle: "Mini-Project",
    details: `MongoDB based Web Application for aggregating, analysing data to provide users with ease of access to past exam questions which resulted in increasing marks.
      [Softwares Used]: Browser, IntelliJ
      [Technologies Used]: HTML, CSS, JScript, JQuerry, MongoDB, Java, Spark, FreeMarker.
       #3TierArchitecture.`,
    link: "https://www.youtube.com/watch?v=-E2okY8q4U0",
    linkText: "Demo",
  },
  {
    dateProp: "Feb 2017",
    iconProp: <FaTrophy />,
    title: "4th Rank Nationally in Season 2 of Dell Campassador Program",
    subtitle: "Campus Ambassador for Dell",
    details: `During my third year of college, I did various activities - technical and non-technical that eventually ranked me in 4th nationally. There were over 250 dell campassadors across India.`,
    link:
      "https://media.licdn.com/media-proxy/ext?w=800&h=800&f=n&hash=GujJ%2BJ8MX5a4xjXIFg73uc%2Fw6%2BM%3D&ora=1%2CaFBCTXdkRmpGL2lvQUFBPQ%2CxAVta5g-0R6jnhodx1Ey9KGTqAGj6E5DQJHUA3L0CHH05IbfPWjvK8GLKrT08EBDcHgCjQBjLei1ETSzQ47tK4y6eNx22J7kLcL5agYUbhl4j3lK6w",
    linkText: "Image",
  },
  {
    dateProp: "Apr 2017",
    iconProp: <FaTrophy />,
    title: "Finalist in Smart India Hackathon 2017",
    subtitle: "Ministry of Steel - Government of India",
    details: `Reached final round of Smart India Hackathon with team. The hackathon was of 36 hours and conducted in Indore for Ministry of Steel. We demonstrated a prototype for Power Theft Detection involving hardware and software.`,
    link: "https://www.youtube.com/watch?v=0PDnGr--PF4&t=29s",
    linkText: "Video",
  },
  {
    dateProp: "Apr 2017",
    iconProp: <FaTrophy />,
    title: "Best Paper Presentation in Data Science and Analytics Track",
    subtitle: "MIT College of Engineering",
    details: `Won Best Paper Presentation on the topic 'Scope of JavaScript in Data Visualization and Data Analysis'. MIT College of Engineering organized International Conference on "Recent Advances in Computing and Applications" (ICRACA 2017).`,
    link: "#",
    linkText: "",
  },
  {
    dateProp: "Jul 2017 – Mar 2018",
    iconProp: <FaRobot />,
    title: "Bot - Autonomous Emotional Support (BAES)",
    subtitle: "Final year project",
    details: `Chatbot to mitigate or prevent depression and improve positive quotient of a person. Web application consisting of chat interface and most of the positivity boosting tools such as motivational quotes, motivational videos, ambient sounds, trending memes, etc. Data for these tools is scrapped from reddit which enables daily updates. Chat with chatbot is facilitated by AI as well as non-AI components. Mood chart is created by doing semantic analysis that enables person to self-analyze whether his statements to chatbot determine him as depressed or not.
       [Technology Stack]: Python, Tensorflow, Django, Selenium, MongoDB, SQlite, HTML, CSS, and JavaScript.`,
    link: "#",
    linkText: "",
  },
  {
    dateProp: "Jul 2018",
    iconProp: <FaGraduationCap />,
    title: "First Job",
    subtitle: "CLSA",
    details: `Joined as Graduate Engineer Trainee at CLSA, Pune.`,
    link: "https://www.clsa.com/",
    linkText: "Website",
  },
  {
    dateProp: "Oct 2018",
    iconProp: <IoLogoIonic />,
    title: "Top Trending Charts",
    subtitle: "Android App as well as PWA possible via Ionic",
    details: `I created this app to learn ionic 3 as well as to help myself. I later converted my project base from ionic 3 to ionic 4 and deployed on my domain as PWA: <a href="http://pratikkataria.com/top-charts-aggregator">Web App</a>`,
    link:
      "https://play.google.com/store/apps/details?id=com.pratikkataria.topchartsaggregator",
    linkText: "Playstore",
  },
  {
    dateProp: "Dec 2018",
    iconProp: <FaTrophy />,
    title: "Hackpulsate 2018 Winner",
    subtitle: "CLSA",
    details: `Won CLSA's first hackathon organized across HongKong and India for its employees. Created a multi-utility bot in python that integrated with symphony. We were able to use REST APIs, sentiment analysis, NLP, Angular, Regex, and lot of efforts to make our champion bot. Our team of 3, incl. me, were awarded Garmin watches for the victory.`,
    link:
      "https://www.linkedin.com/feed/update/urn:li:activity:6501391142184157184",
    linkText: "Post",
  },
  {
    dateProp: "Apr 2019",
    iconProp: <FaReact />,
    title: "Positivity Hub",
    subtitle: "React Native App",
    details: `I started making this app with the objective of learning React Native at first but then I ended up enhancing it for the true goal of spreading positivity. I just have a shared hosting with minimal capabilities to back it but it's still boosted by what firebase has to offer. There is no fancy automation in collecting the data in app even though I could do it but it wouldn't be as quality content. I take time out from my own schedule to handpick the content for my small positive (as a result of the app) user-base.`,
    link:
      "https://play.google.com/store/apps/details?id=com.pratikkataria.positivityhub",
    linkText: "Playstore",
  },
  {
    dateProp: "Jan 2020",
    iconProp: <FaReact />,
    title: "Web Nuggets",
    subtitle: "Design Handbook",
    details: `The goal is to create a reference for web design and development for quick bootstrapping.`,
    link: "https://pratikkataria.com/web-nuggets/",
    linkText: "Website",
  },
  {
    dateProp: "Mar 2020",
    iconProp: <FaGraduationCap />,
    title: "Previous Job",
    subtitle: "Credit Suisse",
    details: `Joined as Full Stack Developer in APAC Technology & Change.`,
    link: "https://www.credit-suisse.com/",
    linkText: "Website",
  },
  {
    dateProp: "Mar 2020",
    iconProp: <FaReact />,
    title: "Explore Possibility",
    subtitle: "Created in Expo (React Native)",
    details: `The idea is simple - everyone has some skill or topic of expertise that they can utilize in their spare time for good of others or to earn a little. The app provides a platform for putting yourself out there with a system to explore a possibility with someone else. The more people join in, the more possibilities can be explored. The possibility can be anythhing - from a collaboration to a job of sorts.`,
    link:
      "https://play.google.com/store/apps/details?id=com.pratikkataria.explorepossibility",
    linkText: "Playstore",
  },
  {
    dateProp: "Nov 2022",
    iconProp: <FaGraduationCap />,
    title: "Current Job",
    subtitle: "Plivo",
    details: `Joined as SDE2-UI`,
    link: "https://www.plivo.com/",
    linkText: "Website",
  },
].reverse();

const trans = (top) => `translateY(${top}px)`;

const Background = ({ heightContainer, backgroundOffset }) => {
  return (
    <AnimatedBackground
      style={
        isMobile
          ? {}
          : {
              transform: backgroundOffset.top.interpolate(trans),
            }
      }
    >
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
    </AnimatedBackground>
  );
};

const TimelineJourney = ({ layoutDimensions }) => {
  const [headingInView, setHeadingInView] = React.useState(false);
  const [topOfBackgroundProps, setTopOfBackgroundProps] = useSpring(() => ({
    top: -50,
    config: {
      mass: 1,
      tension: 280,
      friction: 120,
      duration: 250,
    },
  }));
  const [heightContainer, setHeightContainer] = React.useState(0);
  const [yearSelected, setYearSelected] = React.useState("");
  const [yearFilterBy, setYearFilterBy] = React.useState("");
  const [timelineData, setTimelineData] = React.useState(TIMELINE_DATA);

  const years = new Set(
    TIMELINE_DATA.map(({ dateProp }) => {
      const numbers = dateProp.replace(/\D/g, "");
      if (numbers.length > 4) {
        return numbers.substring(0, 4);
      }
      return numbers;
    })
  );

  const yearFilter = (data, year) => {
    return data.filter(
      ({ dateProp }) => year.indexOf(dateProp.replace(/\D/g, "")) !== -1
    );
  };

  React.useEffect(() => {
    const trackScrolling = (event) => {
      const headingFrame = document.getElementById("heading-frame");
      let offset = offsetElement(document.getElementById("timeline"));
      // setIsScrolling(true)
      if (offset.visible) {
        setTopOfBackgroundProps({ top: offset.offset });
      } else if (!offset.visible) {
        setTopOfBackgroundProps({ top: 200 });
      }
      if (reachedElement(headingFrame)) {
        setHeadingInView(true);
      } else {
        setHeadingInView(false);
      }
    };
    setHeightContainer(document.getElementById("timeline").clientHeight);
    // setTopOfBackground(originalOffset)
    // setTimeout(() => {
    //   setIsScrolling(false)
    // }, 150)
    window.addEventListener("scroll", trackScrolling);
    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, [setTopOfBackgroundProps]);

  React.useEffect(() => {
    if (yearSelected) {
      setYearFilterBy(yearSelected);
      setTimelineData(yearFilter(TIMELINE_DATA, yearSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSelected]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    swipeToSlide: true,
    centerPadding: "20px",
    className: "custom-slide-spacing",
  };

  return (
    <Section id="timeline" style={{ position: "relative", overflow: "hidden" }}>
      <Frame>
        <HeadingFrame id="heading-frame">
          <Container>
            <Heading blink={headingInView}>Timeline</Heading>
            <SliderContainer>
              <React.Suspense fallback={<p>Loading...</p>}>
                <Slider {...settings}>
                  {Array.from(years).map((year) => (
                    <FilterBlock
                      key={year}
                      className={year === yearFilterBy ? "selected" : ""}
                      onClick={() => {
                        if (year === yearSelected) {
                          setYearFilterBy("");
                          setTimelineData(TIMELINE_DATA);
                          setYearSelected("");
                        } else setYearSelected(year);
                      }}
                    >
                      {year}
                    </FilterBlock>
                  ))}
                </Slider>
              </React.Suspense>
            </SliderContainer>
          </Container>
        </HeadingFrame>
        <VerticalTimeline>
          {timelineData.map((value, key) => (
            <TimelineComponent key={value.title} {...value} />
          ))}
        </VerticalTimeline>
      </Frame>
      <Background
        heightContainer={heightContainer}
        backgroundOffset={topOfBackgroundProps}
      />
    </Section>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.layoutDimensions) ===
    JSON.stringify(nextProps.layoutDimensions)
  );
}

export default React.memo(TimelineJourney, arePropsEqual);
