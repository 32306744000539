import styled, { keyframes } from "styled-components";
import fontFiles from "./fonts";

export const NeonBlink = keyframes`
78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }

  100% {
    color: inherit;
    text-shadow: inherit;
  }
`;

export const GlassHeading = styled.h1`
  @font-face {
    font-family: "Glasshouse";
    font-style: normal;
    font-weight: normal;
    src: local("glashou"), url(${fontFiles.GlassHouseFont}) format("truetype");
  }
  font-family: "Glasshouse";
  font-size: 5rem;
  color: ${(props) => props.theme.color_theme_dark};
`;

export const StrokedButton = styled.button`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  height: inherit;
  width: 10rem;
  transition: all 0.25s;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  user-select: none;
  color: ${(props) => props.theme.color_theme_dark};
  border: 2px solid ${(props) => props.theme.color_theme_dark};
  padding: 5px;
  background-color: transparent;
  &:hover {
    background-color: ${(props) => props.theme.color_theme_dark};
    color: ${(props) => props.theme.color_theme_primary};
    a {
      color: ${(props) => props.theme.color_theme_primary};
    }
  }
  &:active {
    color: ${(props) => props.theme.color_theme_dark};
    background-color: transparent;
  }
`;

export const PrimaryButton = styled.div`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  height: inherit;
  width: 10rem;
  transition: all 0.25s;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  user-select: none;
  color: ${(props) => props.theme.color_theme_dark};
  border: 2px solid ${(props) => props.theme.color_theme_primary};
  padding: 5px;
  background-color: ${(props) => props.theme.color_theme_primary};
  &:hover {
    background-color: ${(props) => props.theme.color_theme_dark};
    color: ${(props) => props.theme.color_theme_primary};
    a {
      color: ${(props) => props.theme.color_theme_primary};
    }
    border: 2px solid ${(props) => props.theme.color_theme_primary};
  }
  &:active {
    color: ${(props) => props.theme.color_theme_dark};
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.color_theme_primary};
  }
`;

export default styled;
