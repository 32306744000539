import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const ImgContainer = styled.div`
  width: 100px;
  height: 30px;
  margin: 0 5px;
`;

const CompanyLogo = ({ styleToUse }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "credit-suisse-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <ImgContainer>
        <Img
          style={styleToUse || {}}
          fluid={data.placeholderImage.childImageSharp.fluid}
        />
      </ImgContainer>
    )}
  />
);
export default CompanyLogo;
