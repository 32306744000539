import React from "react";
import SocialIcons from "./social-icons";
import styled, { keyframes } from "styled-components";
// import { SectionLink } from "react-scroll-section";
import { Menu, Item } from "./Menu";
import { isMobile } from "react-device-detect";
import { Spring, animated, interpolate } from "react-spring/renderprops";
import { enableBodyScroll } from "body-scroll-lock";

const RiseFromAshes = keyframes`
0% {
  transform: translateY(100vh) scaleX(0);
  opacity: 0;
}
100% {
  transform: translateY(0) scaleX(1);
  opacity: 1;
}
`;
const RushedArrival = keyframes`
0% {
  transform: translateX(100vw) skewX(-60deg);
}
75% {
  transform: translateX(0) skewX(-30deg);
}
100% {
  transform: skewX(0);
}
`;

const Header = styled(animated.span)`
  margin-bottom: 8px;
  font-size: 2.45rem;
  /* animation: ${RiseFromAshes} 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
  will-change: transform, opacity;
  opacity: 0;
  transition: filter 2s ease;
`;

const SubHeading = styled(animated.div)`
  font-size: 1.2rem;
  font-weight: 700;
  /* animation: ${RiseFromAshes} 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
  will-change: transform, opacity;
  opacity: 0;
  transition: filter 2s ease;
`;

const SocialIconsContainer = styled(animated.div)`
  display: block;
  /* animation: ${RushedArrival} 1000ms ease-out 1; */
  will-change: transform, opacity;
  transform-origin: bottom;
  opacity: 0;
`;

const HeadingText = styled.h1`
  display: flex;
  flex-direction: column;
  .shadow {
    filter: drop-shadow(0px 2px 8px #00000055);
  }
`;

const TitleHeader = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.color_theme_primary};
  display: flex;
  justify-content: center;
  min-height: 360px;
  height: 100vh;
  overflow: hidden;
  padding: 0 32px;
  position: relative;
  text-align: center;
  flex-direction: column;
`;

const WordpressAction = styled.div`
  position: absolute;
  width: 100%;
  top: 20px;
  right: 20px;
`;

const TopMenu = styled(Menu)`
  top: 5px;
  left: -5px;
  height: calc(1rem + 20px);
  justify-content: flex-end;
`;

const FrontHeader = () => {
  const [animationOver, setAnimationOver] = React.useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(false);
  const MENU_ITEMS = [
    { name: "About", route: "about" },
    { name: "Tech Stack", route: "technology" },
    { name: "Timeline", route: "timeline" },
    { name: "Resume", route: "resume" },
  ];
  React.useEffect(() => {
    if (animationOver) {
      enableBodyScroll(document.body);
      document.getElementsByTagName("html")[0].style = "";
    }
  }, [animationOver]);
  return (
    <TitleHeader id="front-header">
      {/* <WordpressAction>
        <TopMenu>
          <Item
            style={{
              opacity: animationOver ? 1 : 0,
              transform: animationOver ? "translateY(0px)" : "translateY(10px)",
            }}
            link="http://wordpress.pratikkataria.com"
          >
            Old Portfolio
          </Item>
        </TopMenu>
      </WordpressAction> */}
      <HeadingText>
        <Spring
          native
          from={{ transform: "translateY(100vh) scaleX(0)", opacity: 0 }}
          to={{ transform: "translateY(0) scaleX(1)", opacity: 1 }}
          delay={250}
          duration={750}
          onRest={() => setIsHeaderVisible(true)}
        >
          {(props) => (
            <Header style={props} className={isHeaderVisible ? "shadow" : ""}>
              Hello, I'm{" "}
              {typeof window !== "undefined" && window.innerWidth <= 510 && (
                <br />
              )}{" "}
              Pratik Kataria
            </Header>
          )}
        </Spring>
        <Spring
          native
          from={{ transform: "translateY(100vh) scaleX(0)", opacity: 0 }}
          to={{ transform: "translateY(0) scaleX(1)", opacity: 1 }}
          delay={1100}
          duration={750}
        >
          {(props) => (
            <SubHeading className={animationOver ? "shadow" : ""} style={props}>
              Versatile Developer{" "}
              <span role="img" aria-label="developer">
                👨‍💻
              </span>
            </SubHeading>
          )}
        </Spring>
      </HeadingText>
      <Spring
        native
        from={{ x: 0, sx: 0, opacity: 0 }}
        to={{ x: 1, sx: 1, opacity: 1 }}
        delay={2000}
        onRest={() => setAnimationOver(true)}
      >
        {(props) => (
          <SocialIconsContainer
            style={{
              transform: interpolate(
                [
                  props.x.interpolate({
                    range: [0, 0.75, 1],
                    output: [100, 0, 0],
                  }),
                  props.sx.interpolate({
                    range: [0, 0.75, 1],
                    output: [60, 30, 0],
                  }),
                ],
                (x, sx) => `translateX(${x}px) skewX(-${sx}deg)`
              ),
              opacity: props.opacity,
            }}
          >
            <SocialIcons />
          </SocialIconsContainer>
        )}
      </Spring>

      {!isMobile && (
        <Menu>
          {MENU_ITEMS.map(({ name, route }, idx) => (
            <Item
              key={idx.toString()}
              style={{
                opacity: animationOver ? 1 : 0,
                transform: animationOver
                  ? "translateY(0px)"
                  : "translateY(10px)",
                transitionDelay: animationOver ? `${(idx + 1) * 0.33}s` : "0",
              }}
              section={route}
            >
              {name}
            </Item>
          ))}
        </Menu>
      )}
    </TitleHeader>
  );
};

export default FrontHeader;
