import React from "react";
import styled, { css } from "styled-components";
import { Section } from "react-scroll-section";
// import { Document, Page } from "react-pdf";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import ResumeDownload from "../static/Resume.pdf";
import { GlassHeading, NeonBlink } from "../shared/styled-components";
import reachedElement from "../shared/utils";

const HeadingFrame = styled.div`
  height: 200px;
  width: 90%;
  margin: 0 auto;
  max-width: 1170px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ::before {
    left: 50.2%;
    margin-left: -2px;
    content: "";
    position: absolute;
    height: 200px;
    width: 4px;
    background-color: #cf4646;
    background-color: var(--color-theme-primary);
    @media only screen and (max-width: 1168px) {
      left: 18px;
      margin-left: 0px;
    }
  }
  @media only screen and (max-width: 1168px) {
    width: 95%;
  }
`;

const Heading = styled(GlassHeading)`
  color: #fee;
  height: 4rem;
  animation: ${(props) =>
    props.blink
      ? css`
          ${NeonBlink} 2000ms linear 0.01s 1 reverse
        `
      : "none"};
  z-index: 2;
  text-align: center;
  background-color: ${(props) => props.theme.color_theme_primary};
  margin: auto;
  border-radius: 5px;
  padding: 10px;
  line-height: 2rem;
  text-shadow: ${(props) => props.theme.text_shadow_neon_glow};
  @media only screen and (max-width: 1168px) {
    margin: 0 auto;
  }
`;

const ActionButton = styled.button`
  width: fit-content;
  border: 3px solid ${(props) => props.theme.color_theme_dark};
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
  margin: 5px;
  color: ${(props) => props.theme.color_theme_dark};
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.color_theme_dark};
    color: ${(props) => props.theme.color_theme_mid};
    a {
      color: white;
    }
  }
  &:active {
    background-color: transparent;
    color: ${(props) => props.theme.color_theme_dark};
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: ${(props) => props.theme.color_theme_primary};
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: ${(props) => props.theme.flex_direction_mobile};
  justify-content: space-around;
  width: 100%;
  align-items: center;
  ${(props) =>
    props.displayResume &&
    css`
      @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
        flex-direction: ${(props) => props.theme.flex_direction_desktop};
      }
    `}
`;

const ResumeContainer = styled.div`
  margin-bottom: 3rem;
  box-shadow: ${(props) => props.theme.boxShadows.shadow200};
  transition: box-shadow 0.5s ease;
  &:hover {
    box-shadow: ${(props) => props.theme.boxShadows.shadow500};
  }
`;

const ResumeBlock = ({ displayResume }) => {
  if (!displayResume) return null;
  return (
    <ResumeContainer>
      <Document
        file={{
          url: "https://pratikkataria.com/assets/files/Resume.pdf",
        }}
      >
        <Page key="page1" pageNumber={1} />
      </Document>
    </ResumeContainer>
  );
};

const ResumeBlockMemo = React.memo(ResumeBlock);

const Resume = () => {
  const [displayResume, setDisplayResume] = React.useState(false);
  const [headingInView, setHeadingInView] = React.useState(false);

  const trackScrolling = (event) => {
    const headingFrame = document.getElementById("resume-heading-frame");
    if (reachedElement(headingFrame)) {
      setHeadingInView(true);
    } else {
      setHeadingInView(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", trackScrolling);
    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  return (
    <Section id="resume">
      <Frame>
        <HeadingFrame id="resume-heading-frame">
          <Heading blink={headingInView}>Resume</Heading>
        </HeadingFrame>
        <ResumeBlockMemo displayResume={displayResume} />
        <ActionButtons displayResume={displayResume}>
          <ActionButton
            onClick={() => {
              setDisplayResume(!displayResume);
            }}
          >
            {!displayResume ? "View" : "Hide"} Resume
          </ActionButton>
          <ActionButton>
            <a href={ResumeDownload} download>
              Download Resume
            </a>
          </ActionButton>
        </ActionButtons>
      </Frame>
    </Section>
  );
};

export default React.memo(Resume);
