import React from "react";
import { Spring, animated, interpolate } from "react-spring/renderprops";
import styled from "styled-components";
import { interpolate as flubInterpolate } from "flubber";

const TRIANGLE = "M20,380 L380,380 L380,380 L200,20 L20,380 Z";
const RECTANGLE = "M20,20 L20,380 L380,380 L380,20 L20,20 Z";

const interpolator = flubInterpolate(TRIANGLE, RECTANGLE);

const styles = {
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    willChange: "background",
  },
  shape: { width: 140, height: 140, willChange: "transform" },
  center: {
    position: "absolute",
    left: "auto",
    right: "auto",
    top: "auto",
    bottom: "auto",
    margin: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const RelativeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const AnimContainer = styled(animated.div)`
  .tech-name {
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  &:hover {
    svg {
      fill: #fff9 !important;
    }
    .tech-name {
      background-color: #fff9 !important;
      color: #252c36 !important;
    }
  }
`;

const SvgContainer = styled(animated.svg)`
  transition: fill 0.2s ease;
`;

const TechRevealer = ({ name, svg }) => {
  const [toggle, setToggle] = React.useState(true);
  const toggler = () => {
    setToggle(!toggle);
  };
  return (
    <Spring
      native
      from={{ fill: "black" }}
      to={{
        fill: toggle ? "#252c36" : "#252c36",
        rotate: toggle ? "0deg" : "180deg",
        scale: toggle ? 0.3 : 0.7,
        svgScale: toggle ? 0.1 : 1,
        shape: toggle ? 0 : 1,
        opacity: toggle ? 1 : 0,
        reveal: toggle ? 0 : 1,
        antiRotate: toggle ? "180deg" : "0deg",
      }}
      toggle={toggler}
    >
      {({
        toggle,
        backgroundColor,
        fill,
        rotate,
        scale,
        svgScale,
        shape,
        opacity,
        reveal,
        antiRotate,
      }) => (
        <AnimContainer
          style={{ ...styles.container, backgroundColor }}
          title={name}
        >
          <SvgContainer
            style={{
              ...styles.shape,
              fill,
              transform: interpolate(
                [rotate, scale],
                (r, s) => `rotate3d(0,1,0,${r}) scale(${s})`
              ),
            }}
            version="1.1"
            viewBox="0 0 400 400"
          >
            <g fillRule="evenodd">
              <animated.path id="path-1" d={shape.interpolate(interpolator)} />
            </g>
          </SvgContainer>
          <Container
            style={{
              ...styles.center,
              height: "100%",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={toggler}
          >
            <RelativeContainer>
              <animated.div
                style={{
                  ...styles.center,
                  transform: interpolate(
                    [rotate],
                    (r) => `rotate3d(0,1,0,${r})`
                  ),
                  opacity,
                  backgroundColor: "#252c36",
                  padding: "0.025rem 0.25rem",
                }}
                className="tech-name"
              >
                {name}
              </animated.div>
              <animated.div
                style={{
                  ...styles.center,
                  opacity: reveal,
                  zIndex: 2,
                  transform: interpolate(
                    [antiRotate, svgScale],
                    (r, s) => `rotate3d(0,1,0,${r}) scale(${s})`
                  ),
                }}
              >
                {svg}
              </animated.div>
            </RelativeContainer>
          </Container>
        </AnimContainer>
      )}
    </Spring>
  );
};

export default TechRevealer;
