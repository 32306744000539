import React from "react";
import CompanyLogo from "./CompanyLogo";
import AboutPic from "./about-pic";
import styled from "styled-components";
import { Section } from "react-scroll-section";
import { TextSwoopAnimated, ImageReveal } from "../shared/animation-component";
import { isMobile } from "react-device-detect";
import PlivoLogo from "./PlivoLogo";

const RightSubSection = styled.div`
  position: relative;
  flex: 1 1 50%;
  align-self: stretch;
  width: 100%;
  text-align: justify;
  background-color: ${(props) => props.theme.color_theme_dark};
  color: ${(props) => props.theme.color_text_lighter};
  > .gatsby-image-wrapper {
    position: static !important;
  }
`;

const LeftSubSection = styled.div`
  flex: 1 1 50%;
  align-self: stretch;
  padding: ${(props) => props.theme.spacing_l};
  font-size: ${(props) => props.theme.fit_text_size_s};
  line-height: ${(props) => props.theme.fit_text_line_height_s};
  @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
    font-size: ${(props) => props.theme.fit_text_size_m};
    line-height: ${(props) => props.theme.fit_text_line_height_m};
  }
  @media only screen and (min-width: ${(props) => props.theme.tablet_bp}) {
    font-size: ${(props) => props.theme.fit_text_size_l};
    line-height: ${(props) => props.theme.fit_text_line_height_l};
  }
  background-color: white;
`;

const Frame = styled.div`
  align-items: center;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  flex-direction: ${(props) => props.theme.flex_direction_mobile};
  @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
    flex-direction: ${(props) => props.theme.flex_direction_desktop};
  }
`;

const About = () => (
  <Section id="about">
    <Frame>
      <Container>
        <LeftSubSection>
          <TextSwoopAnimated>
            Currently I work as <strong>SDE II UI</strong> for{" "}
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.plivo.com/"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <PlivoLogo />
            </a>{" "}
            - a leading cloud communication platform that helps companies
            quickly program voice and SMS capabilities into their applications.
            I previously worked as{" "}
            <strong>Full Stack Developer, APAC Technology and Change</strong> at
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.credit-suisse.com/"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                padding: "0 8px",
              }}
            >
              <CompanyLogo />
            </a>
            - a global wealth manager, investment bank and financial services
            firm founded and based in Switzerland. In my BAU, I work with
            React/Javascript/Typescript/CSS on Front-End and Python on Back-End.
            Besides this, in distant past I have worked with Wordpress, Ionic 4,
            MongoDB, MySQL/PostgreSql and Android. Find out more about me via{" "}
            <a
              href="https://www.linkedin.com/in/pratikkataria/"
              rel="noreferrer noopener"
              target="_blank"
              className="underline"
            >
              LinkedIn
            </a>
          </TextSwoopAnimated>
        </LeftSubSection>
        <RightSubSection>
          <ImageReveal delay={isMobile ? 0 : 850}>
            <AboutPic />
          </ImageReveal>
        </RightSubSection>
      </Container>
    </Frame>
  </Section>
);

export default About;
