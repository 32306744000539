import React from "react";
import { SectionLink } from "react-scroll-section";
import styled from "styled-components";

const Menu = styled.ul`
  position: absolute;
  bottom: 1rem;
  display: flex;
  margin: 0;
  width: 100%;
  left: 0;
  right: 0;
  justify-content: center;
  flex-wrap: wrap;
  li {
    transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

const Item = styled.li`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  height: inherit;
  transition: all 0.25s;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  user-select: none;
  color: ${(props) => props.theme.color_theme_dark};
  border: 2px solid ${(props) => props.theme.color_theme_dark};
  padding: 5px;
  margin: 5px;
  &:hover {
    background-color: ${(props) => props.theme.color_theme_dark};
    color: ${(props) => props.theme.color_theme_primary};
    a {
      color: ${(props) => props.theme.color_theme_primary};
    }
  }
  &:active {
    color: ${(props) => props.theme.color_theme_dark};
    background-color: transparent;
  }
`;

const MenuItem = ({ section, children, link, style }) => (
  <>
    {section ? (
      <SectionLink section={section}>
        {(link) => (
          <Item
            onClick={link.onClick}
            style={{ ...style }}
            selected={link.isSelected}
          >
            {children}
          </Item>
        )}
      </SectionLink>
    ) : (
      <Item style={{ ...style }}>
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {children}
        </a>
      </Item>
    )}
  </>
);

export { MenuItem as Item, Menu };

export default styled;
