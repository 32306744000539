import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const AboutPic = () => (
  <StaticQuery
    query={graphql`
      query {
        aboutpic: file(relativePath: { eq: "pratik-kataria-cover.jpeg" }) {
          ...fluidFillImage
        }
      }
    `}
    render={(data) => (
      <Img alt="Pratik cover pic" fluid={data.aboutpic.childImageSharp.fluid} />
    )}
  />
);
export default AboutPic;
