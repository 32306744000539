import React from "react";
import styled from "styled-components";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { Spring, animated } from "react-spring/renderprops";
import { FaRobot, FaLink } from "react-icons/fa";
import { PrimaryButton } from "./styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color_theme_dark};
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: ${(props) => props.theme.flex_direction_mobile};
  justify-content: space-between;
  margin-bottom: 0.2rem;
  .cl-effect-20 {
    margin: 0.3rem 0;
    span {
      min-width: 60px;
    }
    svg {
      width: 100%;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
    flex-direction: ${(props) => props.theme.flex_direction_desktop};
    .cl-effect-20 {
      margin: 0;
    }
  }
`;

const Heading = styled.div`
  padding-right: 0.5rem;
  display: block;
  .vertical-timeline-element-title {
    vertical-align: middle;
  }

  *:nth-child(1) {
    display: inline-block;
    padding-right: 0.5rem;
  }
`;

const DescriptionContainer = styled(animated.div)`
  overflow: hidden;
`;

const SubHeading = styled.h4`
  color: #b1b1b1;
  margin-bottom: 0.5rem;
`;

const TimeRange = styled.span`
  color: #ababab;
  font-size: 0.75em;
  background-color: #171b21;
  box-shadow: inset 0 0 0 0em #0710ef;
  display: inline-block;
  padding: 0.25em 1em 0.2em 1em;
`;

const ButtonContainer = styled.div`
  .cl-effect-20 {
    display: inline-flex;
    a {
      line-height: 37px;
    }
  }
`;

const TimelineComponent = ({
  classNameProp,
  dateProp,
  iconStyleProp,
  iconProp,
  title,
  subtitle,
  details,
  link,
  linkText,
}) => {
  const [isReadMore, setIsReadMore] = React.useState(false);
  return (
    <VerticalTimelineElement
      className={classNameProp || "vertical-timeline-element--work"}
      iconStyle={
        iconStyleProp || {
          backgroundColor: "#cf4646",
          background: "var(--color-theme-primary)",
          color: "#fff",
        }
      }
      icon={iconProp || <FaRobot />}
    >
      <Container>
        <Header>
          <Heading>
            <h3 className="vertical-timeline-element-title">{title}</h3>{" "}
            <TimeRange>{dateProp || null}</TimeRange>
          </Heading>
        </Header>
        <SubHeading className="vertical-timeline-element-subtitle">
          {subtitle}
        </SubHeading>
        <Spring
          native
          force
          config={{ tension: 2000, friction: 100, precision: 1 }}
          from={{ height: isReadMore ? 0 : "auto" }}
          to={{ height: isReadMore ? "auto" : 0 }}
        >
          {(props) => (
            <DescriptionContainer style={props}>
              <p
                className="para"
                dangerouslySetInnerHTML={{ __html: details }}
              />
            </DescriptionContainer>
          )}
        </Spring>
        <ButtonContainer>
          <PrimaryButton onClick={() => setIsReadMore(!isReadMore)}>
            {isReadMore ? "Less" : "Know More"}
          </PrimaryButton>
          {link !== "#" ? (
            <div style={{ display: "inline-block" }} className="cl-effect-20">
              <a
                href={link}
                rel="noopener noreferrer"
                target="_blank"
                style={{ cursor: "pointer" }}
                aria-label={linkText}
                title={linkText}
              >
                <span data-hover={linkText}>
                  <FaLink />
                </span>
              </a>
            </div>
          ) : null}
        </ButtonContainer>
      </Container>
    </VerticalTimelineElement>
  );
};

export default TimelineComponent;
